import React, { useEffect, useState }  from 'react'
import './Connect.css'
import { Form, Button, Col, Row, Container } from 'react-bootstrap';
import db from '../firebase.js';
import scrollTop from '../Assets/scrollTop.svg'
import connectCube from '../Assets/connectCube.json'
import { Player } from '@lottiefiles/react-lottie-player';


function Connect() {
    const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [message,setMessage] = useState("");

    const submitDetails = (e) => {
        e.preventDefault();

        if(name!==""&&email!==""&&message!==""){
            
            db.collection('enquiry').add({
                name:name,
                email:email,
                enquiry:message
            });

            setName('');
            setEmail('');
            setMessage('');
            alert('Query has been submitted successfully');
        }
        else {
            alert('enter all the details to submit your enquiry');
        }
    }
    return (
        <div className="scroll-to-top">
        {isVisible && 
        <div onClick={scrollToTop}>
          <img className="scroll-top" src={scrollTop} alt='Go to top'/>
        </div>}
        <section className='spaceConnect'>
          <Container fluid>
          <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mobileView">
                <div className="styleConnectCube">
                  <Player
                    autoplay
                    loop
                    speed={0.75}
                    src={connectCube} >
                  </Player>
                </div>
            </Col>
            <Col xs={12} sm={12} md={1} lg={1} xl={1}></Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <div className="box-address desktopView">
                <p className="address"> <span className="highlight">address:</span> gently altered  <span className="Digit-style">201</span>, <br></br> kshitij, veera desai road,  andheri (w), mumbai - <span className="Digit-style">400058</span> </p>
              </div>
              <div className="box-address mobileView">
                <p className="address"> <span className="highlight">address:</span> gently altered <br></br>  <span className="Digit-style">201</span>, kshitij, veera desai road,  andheri (w), mumbai - <span className="Digit-style">400058</span> </p>
              </div>
              <div className='padLeft'>
                <Form onSubmit={submitDetails}>
                  <Form.Group style={{ marginTop: 15 }}>
                    <input className="input-style" type="text" placeholder="name" value={name} onChange={(e) => setName(e.target.value)} />
                  </Form.Group>

                  <Form.Group style={{ marginTop: 15 }} >
                    <input className="input-style" type="email" value={email} placeholder="email" onChange={(e) => setEmail(e.target.value)} />
                  </Form.Group>

                  <Form.Group style={{ marginTop: 15 }}>
                    <input className="input-style txt-area" value={message} as="textarea" placeholder="message" onChange={(e) => setMessage(e.target.value)} />
                  </Form.Group>

                  {/* <input type="text" value={name} onChange={e=>setName(e.target.value)}></input> */}
                  <div className='centre'>
                    <Button className="btn-connect" type="submit">send</Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col xs={12} sm={12} md={1} lg={1} xl={1}></Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="desktopView">
                <div className="styleConnectCube">
                  <Player
                    autoplay
                    loop
                    speed={0.75}
                    src={connectCube} >
                  </Player>
                </div>
            </Col>
          </Row>
          </Container>
        </section>
        
        </div>
    )
}

export default Connect