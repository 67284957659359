import React from 'react'
import './Home.css'


function Footer() {
    return (
        <div>
            <section>
                <div className="container-fluid">
                    <p className="copyright">© <span className="Digit-style">2021</span> gently altered | all rights reserved</p>
                </div>
            </section>
        </div>
    )
}

export default Footer

