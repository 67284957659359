import React, { useEffect, useState } from 'react'
import './Home.css'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Aos from 'aos';
import 'aos/dist/aos.css'
function Home() {

  useEffect(() => {
    Aos.init()
  }, [])
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth)

  const screenResize = () => {
    // console.log('render',window.innerWidth)
    setScreenWidth(window.innerWidth)
  }
  React.useEffect(() => {
    window.addEventListener('resize', screenResize)

    return () => {
      window.removeEventListener('resize', screenResize)
    }
  }, [])
  return (
    <div style={{}}>
      <div className="mobile-view"  >
        <div style={{ flex: 1, height: '95%', display: 'flex', justifyContent: screenWidth < 995 ? 'flex-start' : 'center', flexDirection: 'column' }}>
          <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" className="home-head">We are an  <span className="highlight">experience design studio</span></h2>
          <h2 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="home-head">aiming to bridge the gap between <span className="highlight">design and technology</span></h2>
          <h2 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="2000" className="home-head">To create elevated <span className="highlight">live, content and digital experiences.</span></h2>
        </div>
        <div className="home_lottie" style={{ flex: .7, height: '100%' }}>
          <Player
            autoplay
            loop
            style={{ height: 500 }}
            src="https://assets8.lottiefiles.com/packages/lf20_ybrg8gup.json">
          </Player>
        </div>
      </div>
    </div>
  )
}

export default Home