import React, { useEffect, useState } from 'react'
import { ResponsiveEmbed, Row, Container, Col,Button } from 'react-bootstrap'
import './Work.css'
import db from '../firebase.js';
import Card1 from './Card1'
import Modal from 'react-modal';
import scrollTop from '../Assets/scrollTop.svg'
import showReel from '../Assets/showReel.mp4'
import liveCube from '../Assets/category/live.json'
import contentCube from '../Assets/category/content.json'
import digitalCube from '../Assets/category/digital.json'
import muted from '../Assets/mute.png'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import volume from '../Assets/volume.png'

function Work() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  const [cardList, setCardList] = useState([]);
  const [title, setTitle] = useState('');
  const [show, setShow] = useState(false)
  const [videoUrl, setVideoUrl] = useState('');
  const [desc, setDesc] = useState('');
  const [image, setImage] = useState('');
  const [service, setService] = useState('')
  const [isImage, setIsImage] = useState('')
  const [projectLink, setProjectLink] = useState('');
  const [digital, setDigital] = useState([])
  const [live, setLive] = useState([])
  const [content, setContent] = useState([])
  const [viewAll, setViewAll] = useState([])
  const [projectLinkName, setProjectLinkName] = useState('');
  const [isMuted, setIsMuted] = useState(false)
  const [selectedItem, setSelectedItem] = useState('viewall')

  useEffect(() => {
    const retu = fetchBlogs()

  }, [])

  const fetchBlogs = async () => {
    try {
      const response = db.collection('works');
      const data = await response.get();
      const list = [];
      const digital = [];
      const live = [];
      const content = [];
      const viewall = []
      data.docs.forEach(item => {
        const lstDoc = { idss: item.id, ...item.data() }
        viewall.push(lstDoc);
        setViewAll([...viewall])
        setCardList([...viewall])
        if (lstDoc.service === "digital") {
          digital.push(lstDoc)
          setDigital([...digital])

        }
        if (lstDoc.service === "live") {
          live.push(lstDoc)
          setLive([...live])

        }
        if (lstDoc.service === "content") {
          content.push(lstDoc)
          setContent([...content])
        }
      })

    }
    catch (err) {
      console.log("respssssonse", err)
    }


  }

  const selectedList = (tabName) => {
    switch (tabName) {
      case 'digital': {
        setCardList(digital)
        setSelectedItem('digital')
        break;
      }
      case 'live': {
        setCardList(live)
        setSelectedItem('live')

        break;
      }
      case 'content': {
        setCardList(content)
        setSelectedItem('content')

        break;
      }
      case 'viewall': {
        setCardList(viewAll)
        setSelectedItem('viewall')

        break;
      }

    }
  }
  const setModalData = (url, desc, title, image, showImage, servicee, projectLink, linkName) => {
    setVideoUrl(url)
    setDesc(desc)
    setTitle(title)
    setShow(true)
    setImage(image)
    setIsImage(showImage)
    setService(servicee)
    setProjectLink(projectLink)
    setProjectLinkName(linkName)
  }
  const customStyles = {
    content: {
      height: '90vh',
      width: '90vw',
      zIndex: 12,
      opacity:1,
      backgroundColor: '#000',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };


  const renderList = (data) => {
    return (
      data.map((item, key) => (
        < Card1 id={item.id} title={item.title} service={item.service} image={item.image} setModalData={() => setModalData(item.videourl, item.desc, item.title, item.image, item.isImage, item.service, item.projectlink, item.linkName)} />
      ))
    )

  }
  return (
    <div className="scroll-to-top">
      {isVisible ?
        !show && <div onClick={scrollToTop}>
          <img className="scroll-top" src={scrollTop} alt='Go to top' />
        </div> : ""}
      <div className="reel-top"></div>
      <section className="show-reel">
        <div className="overlay-wcs"><video playsinline="playsinline" autoplay="autoplay" muted={isMuted} loop="loop">
          <source src={showReel} type="video/mp4"></source>
        </video>
        </div>
        <div className="work-muted" onClick={() => setIsMuted(() => !isMuted)}>
          <img src={isMuted ? muted : volume} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
        </div>
      </section>
      <section className="feayured-projects-block">
        <div className="container-fluid" style={show?{display:'none'}:{}}>
          <div className="heading-featured-projects">
            <h1 className="heading-featured-projects-txt">featured projects</h1>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 60 }}>
            <div className="tabbar" onClick={() => { selectedList('live') }}>
              {selectedItem === 'live' ?
                <p className="zoom" style={{ padding: 10, fontWeight: 'bold', color: "#0fff9b" }}>LIVE</p> :
                <p style={{ padding: 10, fontWeight: 'bold', color: 'white' }}>LIVE</p>
              }
            </div>
            <div className="tabbar" onClick={() => { selectedList('content') }}>
              {selectedItem === 'content' ?
                <p className="zoom" style={{ padding: 10, fontWeight: 'bold', color: "#0fff9b" }}>CONTENT</p> :
                <p style={{ padding: 10, fontWeight: 'bold', color: 'white' }}>CONTENT</p>
              }
            </div>
            <div className="tabbar" onClick={() => { selectedList('digital') }}>
              {selectedItem === 'digital' ?
                <p className="zoom" style={{ padding: 10, fontWeight: 'bold', color: "#0fff9b" }}>DIGITAL</p> :
                <p style={{ padding: 10, fontWeight: 'bold', color: 'white' }}>DIGITAL</p>
              }
            </div>
            <div className="tabbar" onClick={() => { selectedList('viewall') }}>
              {selectedItem === 'viewall' ?
                <p className="zoom" style={{ padding: 10, fontWeight: 'bold', color: "#0fff9b" }}>VIEW ALL</p> :
                <p style={{ padding: 10, fontWeight: 'bold', color: 'white' }}>VIEW ALL</p>
              }
            </div>
          </div>
          <br></br>
          <Container fluid>
            <Row style={{ marginTop: 15 }}>
              {renderList(cardList)}
            </Row>
          </Container>
        </div>

      </section>
      <Modal
        isOpen={show}
        onRequestClose={() => setShow(false)}
        style={customStyles} 
        backdrop={false}
      >
        <div style={{ marginTop: 30 }}></div>
        <div style={{ marginBottom: 20 }}>
          <div className='d-flex'>
            <div style={{ width:"80%" }}>
              <h1 className="title">{title}</h1>
            </div>
            <div style={{ width:"20%" }}>
              <div className="style-btn">
                <div onClick={() => setShow(false)} class="outer">
                  <div class="inner">
                    <label>close</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-8 col-lg-8 col-sm-8 col-md-8'>
              <div className="m-b">
                <img src={image} className="projectImages" /> :
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-sm-4 col-md-4'>
              <div className='desktopView'>
                <Player
                  autoplay
                  loop
                  speed={0.75}
                  src={service == "live"?liveCube:service=="digital"?digitalCube:service=="content"?contentCube:""}>
                </Player>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className='displayFlexC'>
            <div>
              <h4 className="service-name-modal"> {service}</h4>
            </div>
            <div className='linkT'>
              <h4 className="para-link"><a href={projectLink} target="_blank">{projectLinkName}</a></h4>
            </div>
          </div>
          <p className="para">{desc[0]}
          </p>
          <p className="para">{desc[1]}
          </p>
          <p className="para">{desc[2]}
          </p>
        </div>
      </Modal>

      
    </div>
    
  )
}



export default Work