import React from 'react'
import './Work.css'
import './Card.css'
// import db from '../firebase.js';
import {Col} from 'react-bootstrap';

export default function Card1(props) {

    return (
        <Col xs={12} sm={12} md={3} lg={3} xl={3} className="DesktopView">
            <div onClick={props.setModalData} className="containern">
                    <div className="card-wrapper-fp newCardStyle" style={{marginBottom:120}}>
                        <div className="card-fp bg-img-1" style={{backgroundImage:`url(${props.image})`}}>
                            <br></br> <br></br> <br></br> <br></br>
                            <h1 className="fp-head"> {props.title}</h1>
                            <div className="service-digital">{props.service}</div>
                        </div> 
                    </div>
            </div>
        </Col>
    )
}

